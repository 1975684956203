import { IconHeartOff16, IconHeartOn16 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

export interface IFavoriteButtonProps {
  checked: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onButtonClick(e: any): void;
}

export const FavoriteButton: React.FC<React.PropsWithChildren<IFavoriteButtonProps>> = ({ checked, onButtonClick }) => {
  return (
    <div className={style['item']} data-testid="FavoriteButton-Button" onClick={onButtonClick}>
      {checked ? <IconHeartOn16 color="error_100" /> : <IconHeartOff16 />}
    </div>
  );
};
