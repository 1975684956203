import { Image, Video } from '@cian/slider';
import { IconPlayArrow16 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './Thumb.css';
import { EMediaType, TMedia } from '../../../../types';
import { CianLayout } from '../../../CianLayout';

interface IThumbProps {
  active?: boolean;
  media: TMedia;
  onClick?(media: TMedia): void;
}

const ThumbComponent: React.FC<React.PropsWithChildren<IThumbProps>> = ({ active, media, onClick }) => {
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      if (onClick) {
        onClick(media);
      }
    },
    [media, onClick],
  );

  const component = React.useMemo<React.ReactNode>(() => {
    switch (media.type) {
      case EMediaType.Layout:
      case EMediaType.Photo: {
        return <Image src={media.thumbnailSrc} objectFit={media.type === EMediaType.Layout ? 'contain' : 'cover'} />;
      }
      case EMediaType.Video:
      case EMediaType.VideoVk:
      case EMediaType.VideoKinescope:
        return (
          <Video
            src={media.src}
            previewUrl={media.previewUrl}
            playButton={
              <div className={styles['play-button']}>
                <IconPlayArrow16 color="white_100" />
              </div>
            }
          />
        );
      case EMediaType.CianLayout:
        return <CianLayout src={media.thumbnailSrc} />;
      case EMediaType.Component:
        return <Image src={media.thumbnailSrc} />;
      case EMediaType.Tour:
        return <Image src={media.thumbnailSrc} />;
    }
  }, [media]);

  return (
    <li className={cx(styles['container'], { [styles['container--active']]: active })}>
      <a href="#" onClick={handleClick}>
        {component}
      </a>
    </li>
  );
};

export const Thumb = React.memo(ThumbComponent);
