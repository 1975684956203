import { ca } from '@cian/analytics';

import { getCustomUrl } from './getCustomUrl';
import { getPartner } from './getPartner';
import { IAnalyticsData } from '../../types';

export type TPromotionType = 'sale' | 'mortgage' | 'bonus';

export interface IExtra {
  offer_id?: number;
  parentId?: number | null;
}

export function getDefaultAnalyticFields(offerId: number, villageId: number) {
  return {
    category: 'Card',
    name: 'oldevent',
    products: [
      {
        id: villageId,
        offerType: 'KP',
        position: 1,
        extra: { offer_id: offerId },
      },
    ],
  };
}

export function trackPromotionsLabelClick(promotionType: TPromotionType, offerId: number, villageId: number) {
  ca('eventSite', {
    ...getDefaultAnalyticFields(offerId, villageId),
    action: 'click_label_photo',
    label: promotionType,
  });
}

export function trackPromotionsIconClick(
  promotionType: TPromotionType,
  position: number,
  offerId: number,
  villageId: number,
) {
  ca('eventSite', {
    ...getDefaultAnalyticFields(offerId, villageId),
    action: 'click_promo_icon',
    label: `${position}/${promotionType}`,
  });
}

export function trackOpenPromotionsPhoneClick(analyticsData: IAnalyticsData, kpId: number | null) {
  const { agent, offer, user, village } = analyticsData;
  if (!village) {
    return;
  }

  let extra: IExtra = {};

  if (offer && offer.id) {
    extra = {
      ...extra,
      offer_id: offer.id,
    };
  }

  if (kpId) {
    extra = {
      ...extra,
      parentId: kpId,
    };
  }

  ca('eventEbc', {
    action: 'Open_kp_promotions',
    category: 'Phones',
    label: getCustomUrl(offer, agent),
    name: 'oldevent',
    partner: getPartner(user, offer),
    products: [
      {
        id: village.id,
        offerType: 'KP',
        position: 1,
        dealType: 'sale',
        objectType: 'KP',
        name: village.name,
        ownerId: village.builderId,
        ownerType: 'developer',
        city_id: village.locationId,
        extra,
      },
    ],
  });
}
