import { Video } from '@cian/slider';
import * as React from 'react';

import * as styles from './VideoPlayable.css';
import { trackVideoPlaying } from '../../tracking';
import { EMediaType } from '../../types';

interface IVideoPlayableProps {
  playable?: boolean;
  src: string;
  previewUrl?: string;
  player: JSX.Element;
  backgroundSize?: 'cover' | 'contain';
  withBackgroundBlur?: boolean;
  priority?: boolean;
  isActive: boolean;
  videoSource: EMediaType;
}

export const VideoPlayable: React.FC<React.PropsWithChildren<IVideoPlayableProps>> = ({
  src,
  playable,
  previewUrl,
  player,
  backgroundSize,
  withBackgroundBlur,
  priority,
  isActive,
  videoSource,
}) => {
  const [isPlaying, setIsPlaying] = React.useState(playable && isActive);
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!playable) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      setIsPlaying(true);
    },
    [playable],
  );

  React.useEffect(() => {
    if (!isActive) {
      setIsPlaying(false);
    }
  }, [isActive]);

  React.useEffect(() => {
    if (isPlaying && isActive) {
      trackVideoPlaying(videoSource);
    }
  }, [isActive, isPlaying, videoSource]);

  if (!isPlaying) {
    return (
      <div className={styles['container']} onClick={handleClick} data-name="VideoPlayable">
        <Video
          src={src}
          previewUrl={previewUrl}
          playButton={<div className={styles['play-button']} />}
          backgroundSize={backgroundSize}
          blurBackground={withBackgroundBlur}
          priority={priority}
        />
      </div>
    );
  }

  return player;
};
