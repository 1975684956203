import { Icon3DPlan16, IconPhoto16, IconPlan16, IconVideo16, UIText1 } from '@cian/ui-kit';
import { IIconProps } from '@cian/ui-kit/icons/internal';
import * as React from 'react';

import * as styles from './Control.css';
import { EMediaType } from '../../../../types';
import { IInfoControlConfig } from '../../types';

interface IControlProps {
  config: IInfoControlConfig;
  onClick?(): void;
}

const ICONS_MAP: { [key in IInfoControlConfig['type']]: React.ComponentType<React.PropsWithChildren<IIconProps>> } = {
  [EMediaType.Layout]: IconPlan16,
  [EMediaType.Photo]: IconPhoto16,
  [EMediaType.Video]: IconVideo16,
  [EMediaType.Tour]: Icon3DPlan16,
};

export const Control: React.FC<React.PropsWithChildren<IControlProps>> = ({ config, onClick }) => {
  const Icon = React.useMemo(() => ICONS_MAP[config.type], [config.type]);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (onClick) {
        onClick();
      }
    },
    [onClick],
  );

  return (
    <li>
      <button className={styles['button']} onClick={handleClick}>
        <span className={styles['icon']}>
          <Icon display="block" color="white_100" />
        </span>
        <UIText1 fontWeight="bold" color="white_100">
          {config.label}
        </UIText1>
      </button>
    </li>
  );
};
