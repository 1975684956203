import { useIsomorphicLayoutEffect } from '@cian/react-utils';
import { IconClose16, ModalFullscreen } from '@cian/ui-kit';
import * as React from 'react';

import { GalleryFullscreenTabs } from 'shared/components/Gallery/components/GalleryFullscreenTabs/GalleryFullscreenTabs';

import * as styles from './GalleryFullscreen.css';
import { TMedia } from '../../types';
import { GalleryInner } from '../GalleryInner';

interface IGalleryFullscreenSlots {
  slotBefore?: React.ReactNode;
  slotSidebar?: React.ReactNode;
}

interface IGalleryFullscreenProps extends IGalleryFullscreenSlots {
  currentSlide: number;
  media: TMedia[];
  initialSlide?: number;
  open?: boolean;
  onSlideChange?(index: number): void;
  onClose?(): void;
}

export const GalleryFullscreen: React.FC<IGalleryFullscreenProps> = ({
  currentSlide,
  slotBefore,
  slotSidebar,
  media,
  initialSlide,
  open,
  onSlideChange,
  onClose = () => {},
}) => {
  const galleryInnerRef = React.useRef<React.ElementRef<typeof GalleryInner>>(null);

  useIsomorphicLayoutEffect(() => {
    /* istanbul ignore next */
    if (open && initialSlide) {
      requestAnimationFrame(() => {
        galleryInnerRef.current?.scrollTo(initialSlide, { behavior: 'auto' });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const scrollTo = React.useCallback((i: number) => {
    galleryInnerRef.current?.scrollTo(i, { behavior: 'auto' });
  }, []);

  return (
    <ModalFullscreen escape open={!!open} theme="white" onClose={onClose}>
      <div className={styles['header']}>
        <GalleryFullscreenTabs scrollTo={scrollTo} currentSlide={currentSlide} media={media} />
        <button
          data-name="GalleryFullscreenClose"
          className={styles['close']}
          title="Закрыть полноэкранную галерею"
          onClick={onClose}
        >
          <IconClose16 display="block" color="gray60_100" />
        </button>
      </div>
      <div data-name="GalleryFullscreen" className={styles['container']}>
        {slotBefore}
        <div className={styles['inner']}>
          <div className={styles['gallery']}>
            <GalleryInner
              autofocus
              ref={galleryInnerRef}
              media={media}
              objectFit="contain"
              thumbsBounded
              videoPlayable
              onSlideChange={onSlideChange}
            />
          </div>
          {slotSidebar}
        </div>
      </div>
    </ModalFullscreen>
  );
};
