import { ca } from '@cian/analytics';

import { getSourceParams } from 'shared/utils/getSourceParams';

const baseEvent = {
  category: 'card',
  name: 'oldevent',
};

export function trackFullScreenOpen() {
  const sourceParams = getSourceParams();

  ca(
    'event',
    Object.assign(
      {
        action: 'Open_card_fullscreen_gallery',
        page: {
          pageType: 'Card',
          siteType: 'desktop',
          extra: {
            pageTypeSource: sourceParams?.source,
            listingTypeSource: sourceParams?.listingType,
          },
        },
      },
      baseEvent,
    ),
  );
}

export function trackClickVideo() {
  ca(
    'event',
    Object.assign(
      {
        action: 'tab_video_photo_block',
      },
      baseEvent,
    ),
  );
}

export function trackClickLayout() {
  ca(
    'event',
    Object.assign(
      {
        action: 'tab_layout_photo_block',
      },
      baseEvent,
    ),
  );
}

export function trackClickPhotos() {
  ca(
    'event',
    Object.assign(
      {
        action: 'tab_photos_photo_block',
      },
      baseEvent,
    ),
  );
}
