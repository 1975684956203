import { IconFullscreen16 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './ButtonFullscreen.css';

export const ButtonFullscreen: React.FC = () => {
  return (
    <span className={styles['container']}>
      <IconFullscreen16 display="block" color="white_100" />
    </span>
  );
};
